import * as React from "react";
import HeaderAndMeta from "../components/HeaderAndMeta";
import Footer from "../components/Footer";
import { Trans } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import CorporateTable from "../components/CorporateTable";

const CorporateData = () => {
  return (
    <main className="corporate-section">
      <HeaderAndMeta />
      <div>
        <section
          id="corporate"
          className="w-full relative z-20 overflow-hidden"
        >
          <div className="absolute right-0 top-0 w-200 h-200 lg:w-320 lg:h-320">
            <img
              className="w-full h-full object-contain"
              width="320"
              height="320"
              src="/images/icons/top-rarr.svg"
              alt="right-arrow"
            />
          </div>
          <div className="container w-full pt-100 md:pt-120 lg:pt-144">
            <p className="subheading">
              <Trans>About dcSpark</Trans>
            </p>
            <h2 className="heading-2">
              <Trans>Corporate Data</Trans>
              <span></span>
            </h2>
          </div>
          <CorporateTable />
        </section>
      </div>
      <Footer />
    </main>
  );
};

export default CorporateData;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
