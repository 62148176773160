import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const CorporateTable = () => {
  return (
    <section id="corporate-table-container" className="relative">
      <div className="pt-skew absolute top-0 left-0 right-0 bottom-0">
        <div className="bg-grey-founders w-full h-full"></div>
      </div>
      <div className="bg-top-left pt-skew"></div>
      <div className="relative pt-skew">
        <div className="container relative text-navy-blue py-24">
          <div className="w-full p-25 md:p-35 border border-grey-border-dark rounded-2xl bg-white lg:-mt-150">
            <table className="corporate-table">
              <tbody>
                <tr>
                  <th>
                    <Trans>Company name</Trans>
                  </th>
                  <td>
                    <Trans>dcSpark Global</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Directors</Trans>
                  </th>
                  <td>
                    <Trans>Sebastien Guillemot & Robert Kornacki</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Address</Trans>
                  </th>
                  <td>
                    <Trans>
                      Floor 4, Banco Popular Building, Road Town, Tortola
                      VG1110, British Virgin Islands
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Foundation date</Trans>
                  </th>
                  <td>
                    <Trans>February 2022</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Services</Trans>
                  </th>
                  <td>
                    <Trans>Blockchain Consulting</Trans>
                  </td>
                </tr>
                <tr>
                  <th>URL</th>
                  <td>
                    <a
                      className="underline"
                      href="https://dcspark.io/jp/"
                      target="_blank"
                    >
                      <Trans>https://dcspark.io</Trans>
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Twitter</Trans>
                  </th>
                  <td>
                    <a
                      className="underline"
                      href="https://twitter.com/dcspark_jp"
                      target="_blank"
                    >
                      <Trans>https://twitter.com/dcspark_io</Trans>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CorporateTable;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
